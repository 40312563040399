import '../../App.css'
import Navbar from '../Navbar'
import About from '../About'
import Footer from '../Footer'
function Home() {
  return (
    <>
      <Navbar></Navbar>
      <About></About>
      <Footer></Footer>
    </>
  )
}

export default Home
