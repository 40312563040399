import React from 'react'
import { Link } from 'react-router-dom'
import './Application.css'

export default function Application() {
  return (
    <div className="applicationwrap">
      <div className="applicationtitle">Registration Information</div>
      <div className="applicationsubtitle">Application Requirement</div>
      <div className="applicationcontents">
        No prior knowledge is needed before attending Summer 2022 programs. We
        welcome everyone to join our two week summer camp!
      </div>
      <div className="applicationsubtitle">Program Payment</div>
      <div className="applicationcontents">
        Voluntutor Cloud is a nonprofit organization. Summer programs held by
        Voluntutor Cloud does not require fees.
      </div>
      <div className="applicationsubtitle">Application Timeline</div>

      <img
        className="applicationtimeline"
        src="/images/application_timeline.png"
      ></img>
      <div className="applywrap">
        <div
          className="applybutton"
          onClick={() => {
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSeDBo5nHU2mpwnTLndkUhhAnA8vyCsY_KcRDpfOhBl-CqJVcw/viewform?usp=sf_link',
              '_blank',
              'noopener,noreferrer',
            )
          }}
        >
          APPLY NOW
        </div>
      </div>
    </div>
  )
}
