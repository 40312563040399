import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

export default function Navbar() {
  return (
    <div className="navbar">
      <Link to="/" className="navbar-logo">
        Voluntutor Cloud
      </Link>
      <ul className="nav-m">
        <li className="nav-item">
          <Link to="/" className="nav-links">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/program" className="nav-links">
            Programs
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-links">
            About
          </Link>
        </li>
      </ul>
      <div
        className="nav-links-mobile"
        onClick={() => {
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeDBo5nHU2mpwnTLndkUhhAnA8vyCsY_KcRDpfOhBl-CqJVcw/viewform?usp=sf_link',
            '_blank',
            'noopener,noreferrer',
          )
        }}
      >
        Register
      </div>
    </div>
  )
}
