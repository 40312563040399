import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Landingpage.css'

export default function Landingpage() {
  const openMeet = () => {
    window.open(
      'https://us05web.zoom.us/j/81954355193?pwd=TnAwK25LUCtwRlduM3lTY2hYaFFZUT09',
      '_blank',
      'noopener,noreferrer',
    )
  }

  return (
    <>
      <div className="landingwrap">
        <div className="herosection">
          <img className="curve" src="/images/uppercurve.png" />
          <div className="landingslogan">
            <div className="landingtitle">Join our Adventure!</div>
            <div className="landingcontent">
              We are VolunTutor Cloud, an online platform dedicated to
              facilitating volunteering projects. Due to our commitment to
              volunteering and the importance of contribution, we are ready to
              announce our summer camp programs in which students will learn
              about Mandarin and Taiwanese culture, international affairs and
              the necessary skills of coding. We sincerely welcome everyone and
              look forward to meeting you soon!
            </div>
            <div
              className="registerbtn"
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSeDBo5nHU2mpwnTLndkUhhAnA8vyCsY_KcRDpfOhBl-CqJVcw/viewform?usp=sf_link',
                  '_blank',
                  'noopener,noreferrer',
                )
              }}
            >
              Register NOW
            </div>
          </div>
          <img className="curve" src="/images/bottomcurve.png"></img>
        </div>
        <div className="summerprograms">
          <div className="landingtitle_summer">Summer Programs</div>
          <div className="programs-wrap">
            <div className="summerprogram-wrap">
              <div className="summerprogram-title">An Adventure to Formosa</div>
              <div className="summerprogram-content">
                Take a peek into the enigmatic Formosa island and join us to
                learn about the mysteries of Taiwanese culture!!
              </div>
              <Link to="/program" className="linklanding">
                <div className="learnmorebutton">Learn more...</div>
              </Link>
            </div>
            <div className="summerprogram-wrap">
              <div className="summerprogram-title">
                International Youth Camp
              </div>
              <div className="summerprogram-content">
                Ignite our passions for world affairs: let’s join hands together
                to effectuate change and be a force for good.
              </div>
              <Link to="/program" className="linklanding">
                <div className="learnmorebutton">Learn more...</div>
              </Link>
            </div>
            <div className="summerprogram-wrap">
              <div className="summerprogram-title">Game Lab: Gameathon</div>
              <div className="summerprogram-content">
                Create a game from Scratch with Scratch! Join us to showcase
                your creativity and bring home a grand game project!
              </div>
              <Link to="/program" className="linklanding">
                <div className="learnmorebutton">Learn more...</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="virtualseminar">
          <div className="landingtitle">Virtual Seminar</div>
          <div className="seminartime">June 11, 2022</div>
          <div className="seminardate">7:00 PM ~ 8:00 PM (Central Time)</div>
          <div className="seminartitle">
            This virtual seminar will be an info session about the details of
            the three summer courses. Join us to learn more about our summer
            programs!
          </div>

          <div className="joinbutton" onClick={openMeet}>
            Join Seminar
          </div>
        </div>
      </div>
    </>
  )
}
