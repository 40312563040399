import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Programs.css'
import { Divider } from '@mui/material'

export default function Programs() {
  var gapi = window.gapi
  /* 
    Update with your own Client Id and Api key 
  */
  var CLIENT_ID =
    '795938816312-5s420h895capck872sg72duj8deeinqf.apps.googleusercontent.com'
  var API_KEY = 'AIzaSyAqvjDBr0y1yOJTrLJ55G2osXIeqTulrwY'
  var DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ]
  var SCOPES = 'https://www.googleapis.com/auth/calendar.events'

  const handleClick = () => {
    gapi.load('client:auth2', () => {
      console.log('loaded client')

      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
          plugin_name: 'streamy',
        })
        .then(() => {
          console.log('client inited')
        })
        .catch((err) => {
          console.log('err while initing', err)
        })

      gapi.client.load('calendar', 'v3', () => console.log('bam!'))

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: 'VolunTutor Cloud Summer Camp Online Session',
            description:
              'Zoom meet link: https://us05web.zoom.us/j/81954355193?pwd=TnAwK25LUCtwRlduM3lTY2hYaFFZUT09',
            start: {
              dateTime: '2022-06-11T19:00:00',
              timeZone: 'America/Chicago',
            },
            end: {
              dateTime: '2022-06-11T20:00:00',
              timeZone: 'America/Chicago',
            },
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 },
                { method: 'popup', minutes: 24 * 60 },
                { method: 'popup', minutes: 12 * 60 },
                { method: 'popup', minutes: 30 },
              ],
            },
          }

          var request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          })

          request.execute((event) => {
            console.log(event)
            window.open(event.htmlLink)
          })

          /*
            Uncomment the following block to get events
        */
          /*
        // get events
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          const events = response.result.items
          console.log('EVENTS: ', events)
        })
        */
        })
    })
  }

  let contents = [
    {
      catchline1: 'Wonder how stinky is stinky tofu?',
      catchline2: 'Wonder what firecrackers have to do with Chinese New Year?',
      content1:
        'An Adventure to Formosa is a two-week program that helps you learn Mandarin. Our intensive courses help you quickly establish listening, speaking, reading, and writing skills. We have also prepared interesting content about Taiwanese cultures. Classes will be divided into small groups, allowing students to plan group projects pertaining to Taiwanese cultures. We welcome people of all ages with different cultures and backgrounds to embrace Taiwanese culture with us!',
      content2:
        'Take a peek into the enigmatic Formosa island and join us to learn about the mysteries of Taiwanese culture!!',
    },
    {
      catchline1: 'Ignite your passions for world affairs:',
      catchline2:
        'Let’s join hands together to effectuate change, be a force for good, and make a positive impact on the world ',
      content1:
        'We will take a deep dive into the concepts of international affairs and learn about real-world problems. Besides that, students will do a group project pertaining to UN Sustainable Development Goals (SDGs) and engage in controversial but informative debate topics, which train their critical thinking skills and public speaking. On the last day, we will gather to share what we have learned and the interesting stories along the journey of the two-week international affairs summer camp.',
      content2: 'Join us to learn about heated debate topics!!',
    },
    {
      catchline1:
        'How does it feel like to be a real game developer? Join us to showcase your creativity and bring home a grand game project! ',
      catchline2: 'Build a game from SCRATCH with SCRATCH!',
      content1:
        'This is a two-week course that teaches you how to build a game from scratch. With no prior coding experience required, all students are welcomed to try their hands at game development. We have prepared amazing content to guide you along the way. In this course, you will learn Scratch basics and work with a team to build a game with the acquired skills. At the end of the course, you would have completed your very first game project and exhibited it at our final game fair.',
      content2: 'Join this course to realize your dream with codes!',
    },
  ]
  let cur_images = [
    '/images/schedule1.png',
    '/images/schedule2.png',
    '/images/schedule3.png',
  ]

  const openMeet = () => {
    window.open(
      'https://us05web.zoom.us/j/81954355193?pwd=TnAwK25LUCtwRlduM3lTY2hYaFFZUT09',
      '_blank',
      'noopener,noreferrer',
    )
  }

  const [contentchoose, setcontent] = useState(0)
  const [status, setstatus] = useState(0)
  const [first, setfirst] = useState(true)
  const [second, setsecond] = useState(false)
  const [third, setthird] = useState(false)
  const [over, setover] = useState(true)
  const [schedule, setschedule] = useState(false)
  const [contentstyle, setcontentstyle] = useState(
    <div className="program_details_contents">
      <div className="program_details_contents_title">Overview</div>
      <div className="program_details_contents_catchline_wrap">
        <div className="program_details_contents_catchline">
          {contents[0]['catchline1']}
        </div>
        <div className="program_details_contents_catchline">
          {contents[0]['catchline2']}
        </div>
      </div>
      <div className="program_details_contents_content">
        {contents[0]['content1']}
      </div>
      <div className="program_details_contents_content">
        {contents[0]['content2']}
      </div>
      <div className="program_details_contents_catchline">
        Application Timeline
      </div>
      <img
        className="applicationtimeline"
        src="/images/application_timeline.png"
      ></img>
      <div
        className="programpage_reg"
        onClick={() => {
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeDBo5nHU2mpwnTLndkUhhAnA8vyCsY_KcRDpfOhBl-CqJVcw/viewform?usp=sf_link',
            '_blank',
            'noopener,noreferrer',
          )
        }}
      >
        Register NOW
      </div>
    </div>,
  )

  const setstylecontent = (num, style) => {
    if (num == 0) {
      setcontentstyle(
        <div className="program_details_contents">
          <div className="program_details_contents_title">Overview</div>
          <div className="program_details_contents_catchline_wrap">
            <div className="program_details_contents_catchline">
              {contents[style]['catchline1']}
            </div>
            <div className="program_details_contents_catchline">
              {contents[style]['catchline2']}
            </div>
          </div>
          <div className="program_details_contents_content">
            {contents[style]['content1']}
          </div>
          <div className="program_details_contents_content">
            {contents[style]['content2']}
          </div>
          <div className="program_details_contents_catchline">
            Application Timeline
          </div>
          <img
            className="applicationtimeline"
            src="/images/application_timeline.png"
          ></img>
          <div
            className="programpage_reg"
            onClick={() => {
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSeDBo5nHU2mpwnTLndkUhhAnA8vyCsY_KcRDpfOhBl-CqJVcw/viewform?usp=sf_link',
                '_blank',
                'noopener,noreferrer',
              )
            }}
          >
            Register NOW
          </div>
        </div>,
      )
    } else {
      setcontentstyle(
        <div className="program_details_contents">
          <div className="program_details_contents_title">Schedule</div>

          <div className="program_details_schedule_wrap">
            <div className="program_details_schedule_title">Course Agenda</div>
            <div className="program_details_contents_content">
              Daily participation and engagement are required Monday through
              Friday. Each day will include some mixture of live, online class
              sessions, facilitated, small group discussions.
            </div>
          </div>
          <div className="program_details_schedule_wrap">
            <div className="program_details_schedule_title">Equipment</div>
            <div className="program_details_contents_content">
              In order to fully participate in this course, you will need
              following equipment.
              <li>Computer, functioning wifi</li>
              <li>Zoom Requirements and Support</li>
            </div>
          </div>
          <div className="program_details_schedule_wrap">
            <div className="program_details_schedule_title">Curriculums</div>
            <img className="schedule_image" src={cur_images[style]}></img>
          </div>
        </div>,
      )
    }
  }
  return (
    <div className="programwrap">
      <div className="program_nav">
        <div
          className={first ? 'program_nav_wrap_active' : 'program_nav_wrap'}
          onClick={() => {
            setstatus(0)
            setfirst(true)
            setsecond(false)
            setthird(false)
            setstylecontent(contentchoose, 0)
          }}
        >
          An Adventure to Formosa
        </div>

        <div className="programwrap_nav">
          <Divider className="programline"></Divider>

          <div
            className={second ? 'program_nav_wrap_active' : 'program_nav_wrap'}
            onClick={() => {
              setstatus(1)
              setfirst(false)
              setsecond(true)
              setthird(false)
              setstylecontent(contentchoose, 1)
            }}
          >
            International Youth Camp
          </div>
          <Divider className="programline"></Divider>
        </div>
        <div
          className={third ? 'program_nav_wrap_active' : 'program_nav_wrap'}
          onClick={() => {
            setstatus(2)
            setfirst(false)
            setsecond(false)
            setthird(true)
            setstylecontent(contentchoose, 2)
          }}
        >
          Game Lab: Gameathon
        </div>
      </div>
      <div className="program_content">
        <div className="program_details">
          <div className="program_details_nav">
            <div
              className={
                over
                  ? 'program_details_nav_wrap_active'
                  : 'program_details_nav_wrap'
              }
              onClick={() => {
                setcontent(0)
                setover(true)
                setschedule(false)
                setstylecontent(0, status)
                console.log(contentstyle)
              }}
            >
              Overview
            </div>
            <div
              className={
                schedule
                  ? 'program_details_nav_wrap_active'
                  : 'program_details_nav_wrap'
              }
              onClick={() => {
                setcontent(1)
                setover(false)
                setschedule(true)
                setstylecontent(1, status)
                console.log(contentstyle)
              }}
            >
              Schedule
            </div>
          </div>
          {contentstyle}
        </div>
        <div className="program_others">
          <div className="programs_wrap">
            <div className="programs_title">Course Date</div>
            <div className="programs_others_content">
              July 11 - July 25 (2 weeks)
            </div>
          </div>
          <Divider className="program_others_line"></Divider>
          <div className="programs_wrap">
            <div className="programs_title">Course Time</div>
            <div className="programs_others_content">
              9:00 AM - 11:00 AM (Central Time)
            </div>
            <div className="programs_others_content">Monday to Friday</div>
          </div>
          <Divider className="program_others_line"></Divider>

          <div className="programs_wrap">
            <div className="programs_title">Virtual Seminar</div>
            <div className="programs_others_content">
              This virtual seminar will be an info session about the details of
              the three summer courses.
            </div>
            <div className="programs_others_content">
              June 11, 2022 7:00 PM (Central Time)
            </div>
            <div className="programpage" onClick={handleClick}>
              Join Seminar
            </div>
          </div>

          <Divider className="program_others_line"></Divider>
          <div className="programs_wrap">
            <div className="programs_title">Application deadline</div>
            <div className="programs_others_content">
              June 18, 2022 9:00 PM (Central Time)
            </div>
          </div>
          <Divider className="program_others_line"></Divider>
          <div className="programs_wrap">
            <div className="programs_title">Application Requirement</div>
            <div className="programs_others_content">
              No prior knowledge is needed before attending Summer 2022
              programs. We welcome everyone to join our two week summer camp!
            </div>
          </div>
          <Divider className="program_others_line"></Divider>
          <div className="programs_wrap">
            <div className="programs_title">Program Payment</div>
            <div className="programs_others_content">
              Voluntutor Cloud is a nonprofit organization. Summer programs held
              by Voluntutor Cloud do not require fees.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
