import React from 'react'
import './About.css'
import { Divider } from '@mui/material'

export default function About() {
  return (
    <div className="aboutwrap">
      <div className="aboutfirst">
        <div className="aboutleft">
          <img className="aboutustitle" src="/images/aboutustitle.png"></img>
          <div className="titleslogan">
            Voluntutor Cloud is a self-initiated online platform aimed at
            facilitating volunteering projects between passionate elementary
            school children and high schoolers.
          </div>
          <div
            className="visit"
            onClick={() => {
              window.open(
                'https://voluntutorcloud.org/',
                '_blank',
                'noopener,noreferrer',
              )
            }}
          >
            Visit Our Website
          </div>
        </div>
        <div className="aboutright">
          <img className="aboutusimage" src="/images/aboutusteacher.png"></img>
        </div>
      </div>
      <img className="curve" src="/images/aboutus_curve.png"></img>
      <div className="aboutsecond">
        <div className="abouttitle">About Us</div>

        <div className="traitwrap">
          <div className="about_ustrait">
            <div className="about_uswrap">
              <img className="about_icons" src="/images/icon1.png"></img>
              <div className="traitename">Global Connections</div>
            </div>
            <Divider className="about_usline"></Divider>
            <div className="traitcontent">
              VolunTutor Cloud bridges the gap of regional barriers. Through an
              online volunteering platform, volunteers can meet individuals from
              across the globe and form lifelong connections.
            </div>
          </div>
          <div className="about_ustrait">
            <div className="about_uswrap">
              <img className="about_icons" src="/images/icon2.png"></img>
              <div className="traitename">Personalized Education</div>
            </div>
            <Divider className="about_usline"></Divider>
            <div className="traitcontent">
              Our volunteering model is not rigid but innovative. We tailor to
              each student’s educational needs. For once, learning can be
              riveting and customized only for you.
            </div>
          </div>
          <div className="about_ustrait">
            <div className="about_uswrap">
              <img className="about_icons" src="/images/icon3.png"></img>
              <div className="traitename">Community Service</div>
            </div>
            <Divider className="about_usline"></Divider>
            <div className="traitcontent">
              It is critically important that we learn to be grateful and give
              back to our community. With the help from VolunTutor Cloud, your
              dream of volunteering can come to fruition.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
