import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import About from './components/pages/Aboutus'
import Summerapp from './components/pages/SummerApp'
import Programs from './components/pages/Summerprogram'
import './components/fonts.css'
import 'font-awesome/css/font-awesome.min.css'
import Application from './components/Application'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/summerapp" element={<Summerapp />}></Route>
          <Route exact path="/program" element={<Programs />}></Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
