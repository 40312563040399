import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { SiLine, SiInstagram, SiGmail } from 'react-icons/si'

export default function Footer() {
  return (
    <div className="landingfooter">
      <div class="footername">
        <Link to="/about" className="namelink">
          Voluntutor Cloud
        </Link>
      </div>
      <div class="footerrights">Voluntutor Cloud © 2022</div>
      <div class="iconswrap">
        <a
          class="icons"
          href="https://line.me/R/ti/p/@564usgab"
          target="_blank"
          aria-label="Line"
        >
          <SiLine class="fab fa-line" />
        </a>
        <a
          class="icons"
          href="https://www.instagram.com/voluntutor_cloud/"
          target="_blank"
          aria-label="Instagram"
        >
          <SiInstagram class="fab fa-instagram" />
        </a>
        <a
          class="icons"
          href="https://mail.google.com/mail/?view=cm&fs=1&to=voluntutorcloud@gmail.com"
          target="_blank"
          aria-label="Gmail"
        >
          <SiGmail class="fa fa-envelope" />
        </a>
      </div>
    </div>
  )
}
